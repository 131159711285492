.links-w.deactivate .links .link:last-child:before {
  animation: none !important;
}
.header-fixed.active {
  top: 0;
  transition-property: top;
  transition-duration: 0.2s;
  transition-timing-function: ease-in;
}
.header-fixed {
  position: fixed;
  z-index: 999;
  width: 100%;
  top: -120px;
  transition-property: top;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
  background: #fff;
}
.header-fixed .topbar {
  background: #f3f3f3;
}
.header-fixed .topbar .topbar-i .logo-w .img-logo {
  width: 120px;
}
@media only screen and (max-width: 740px) {
  .header-fixed .topbar .topbar-i .logo-w {
    width: 100%;
    text-align: center;
  }
}
.header-fixed .topbar .topbar-i .links-w .links .link a {
  color: #003f8f;
}
@media only screen and (max-width: 740px) {
  .header-fixed .topbar .topbar-i .links-w {
    display: none;
  }
}
.header-fixed .topbar .topbar-i .student-area-w a {
  border-color: #003f8f;
}
.header-fixed .topbar .topbar-i .student-area-w a i.fas,
.header-fixed .topbar .topbar-i .student-area-w a span {
  color: #003f8f;
}
@media only screen and (max-width: 940px) {
  .header-fixed .topbar .topbar-i .student-area-w {
    display: none;
  }
}
.header-fixed .prod-fixed {
  display: flex;
  align-items: center;
  height: 70px;
}
.header-fixed .prod-fixed .img-fixed img {
  max-width: 54px;
}
.header-fixed .prod-fixed .info-fixed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-left: 20px;
}
.header-fixed .prod-fixed .info-fixed .course-title {
  margin: 0;
  text-transform: uppercase;
  font-size: 15.7px;
  font-weight: normal;
}
@media only screen and (max-width: 1024px) {
  .header-fixed .prod-fixed .info-fixed .course-title {
    font-size: 14px;
  }
}
.header-fixed .prod-fixed .info-fixed .course-price {
  display: block;
  color: #3799db;
  font-size: 25px;
  font-weight: bold;
  margin: 0 30px 0 10px;
}
.header-fixed .prod-fixed .info-fixed button {
  width: 340px;
  padding: 17px 0;
  line-height: 1;
  font-size: 18px;
}
@media only screen and (max-width: 1024px) {
  .header-fixed .prod-fixed .info-fixed button {
    width: 240px;
    font-size: 15px;
  }
}
@media only screen and (max-width: 750px) {
  .header-fixed .prod-fixed {
    display: none;
  }
}
