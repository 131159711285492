@import "../global/___variables"

.links-w.deactivate
    .links
        .link:last-child:before
            animation: none!important

.header-fixed.active
    top 0
    transition-property: top
    transition-duration: .2s
    transition-timing-function: ease-in
.header-fixed
    position fixed
    z-index 999
    width 100%
    top -120px
    transition-property: top
    transition-duration: .3s
    transition-timing-function: ease-out
    background: white
    .topbar
        background: cinza
        .topbar-i
            .logo-w
                .img-logo
                    width 120px
                +below(740px)
                    width 100%
                    text-align center
            .links-w
                .links
                    .link
                        a
                            color colorp
                +below(740px)
                    display none
            .student-area-w
                a
                    border-color colorp
                    i.fas, span
                        color colorp
                +below(940px)
                    display none
    .prod-fixed
        display flex
        align-items: center
        height 70px
        .img-fixed
            img
                max-width 54px
        .info-fixed
            display flex
            align-items: center
            justify-content space-between
            flex 1
            margin-left 20px
            .course-title
                margin 0
                text-transform uppercase
                font-size 15.7px
                font-weight normal
                +below(1024px)
                    font-size 14px
            .course-price
                display block
                color #3799DB
                font-size 25px
                font-weight bold
                margin 0 30px 0 10px
            button
                width 340px
                padding 17px 0
                line-height 1
                font-size 18px
                +below(1024px)
                    width 240px
                    font-size 15px
        +below(750px)
            display none
